import React from "react";
import "./HeatPumpProducts.css";
import HeatPumpProduct from "./HeatPumpProduct";
import { heatpumps } from "../../portfolio";

function HeatPumpProducts(props) {
  
  return (
    <div className="heatpumps-container">
      <div className="heatpumps-section-container" style={{ color: props.theme.textColor, fontFamily: props.theme.primaryFont }}>
        {heatpumps.data.map((project) => {
          return (
            <>
              {project.imageAlign === "left" && (
                <HeatPumpProduct theme={props.theme} key={project.id} titleColor={props.titleTextColor} textColor={props.textColor} project={project} />
              )}
              {project.imageAlign === "right" && (
                <HeatPumpProduct theme={props.theme} key={project.id} titleColor={props.titleTextColor} textColor={props.textColor} project={project} />
              )}
            </>
          );
        })}
      </div>
        
    </div>
  );
}

export default HeatPumpProducts;