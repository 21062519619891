import "./Logo.css"

function Logo(props) {
    const includeLogo = props.includeLogo;

    return (
        <svg width="400" height="100" xmlns="http://www.w3.org/2000/svg" className="hide-on-mobile" style={{ visibility: includeLogo ? 'visible' : 'hidden' }}>
            <rect width="400" height="100" fill="#3498db" />
            <text x="50%" y="35%" fontFamily="'Arial', sans-serif" fontSize="36" fill="#ffffff" textAnchor="middle" dy=".3em">
                NRV
            </text>
            <text x="50%" y="80%" fontFamily="'Arial', sans-serif" fontSize="24" fill="#ecf0f1" textAnchor="middle">
                Nordiska Rör & VVS
            </text>
        </svg>
    );
}

export default Logo;