import React from "react";
import "./HeatPumpProducts.css";
import HeatPumpsBackgroundImage from "../../components/backgroundImage/HeatPumpsBackgroundImage";

function HeatPumpsInformation(props) {
  return (
    <div className="heatpumps-information-container">
      <div className="heatpumps-information-section-container" style={{ color: props.theme.textColor, fontFamily: props.theme.primaryFont }}>
        
        <HeatPumpsBackgroundImage
          theme={props.theme}
          font={props.theme.primaryFont}
        />
      </div>
        
    </div>
  );
}

export default HeatPumpsInformation;