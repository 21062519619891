import React from "react";
import "./Offering.css";
import OfferingCard from "../../components/offeringCard/OfferingCard.js";
import { offeringSection } from "../../portfolio";

export default function Offerings(props) {
  const theme = props.theme;
  const backgroundColor = props.backgroundColor;
  const serviceBackgroundColor = props.serviceBackgroundColor;
  const iconColor = props.iconColor;
  const buttonColor = props.buttonColor;
  const buttonTextColor = props.buttonTextColor;
  const buttonHoverColor = props.buttonHoverColor
  const textColor = props.textColor;
  const titleColor = props.titleColor;
  const font = theme.primaryFont
  return (
    <div className="offerings-container" style={{ backgroundColor: backgroundColor }}>
      <div className="offerings">
        {offeringSection.services.map((service) => {
          return (
            <OfferingCard
              key={service.id}
              title={service.title}
              imageLink={service.imageLink}
              icon={service.icon}
              image={service.image}
              description={service.description}
              buttonText={service.buttonText}
              buttonTextColor={buttonTextColor}
              buttonHoverColor={buttonHoverColor}
              buttonColor={buttonColor}
              buttonLink={service.buttonLink}
              iconColor={iconColor}
              textColor={textColor}
              titleColor={titleColor}
              backgroundColor={serviceBackgroundColor}
              font={font}
            />
          );
        })}
      </div>
    </div>
  );
}
