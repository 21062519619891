import React, { Component } from "react";
import "./PrivateSewageSystemBackgroundImage.css";
import { Fade } from "react-reveal";

class PrivateSewageSystemBackgroundImage extends Component {
  render() {
    return (
      <Fade>
        <div style={{ position: "relative", fontFamily: this.props.font}}>
          <div className="private-sewage-system-bg-image">
            <div className="private-sewage-system-bg-image-text-container">
              <div className="private-sewage-system-bg-image-form-container">
                <span className="private-sewage-system-bg-image-text-title">
                  Enskilt avlopp
                </span>
                <span className="private-sewage-system-bg-image-text-title">
                  & infiltration
                </span>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default PrivateSewageSystemBackgroundImage;
