// theme.js
export const blueTheme = {
  body: "#EDF9FE",
  text: "#001C55",
  expTxtColor: "#000a12",
  highlight: "#A6E1FA",
  dark: "#00072D",
  secondaryText: "#7F8DAA",
  imageHighlight: "#0E6BA8",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#0A2472",
  headerColor: "#0E6BA877",
  splashBg: "#001C55",
};

export const brownTheme = {
  body: "#FFFEFD",
  text: "#5D2A42",
  expTxtColor: "#000a12",
  highlight: "#FFF9EC",
  dark: "#00072D",
  secondaryText: "#8D697A",
  imageHighlight: "#E29F95",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#FB6376",
  headerColor: "#E29F9577",
  splashBg: "#5D2A42",
};

export const purpleTheme = {
  body: "#F8EFF4",
  text: "#231942",
  expTxtColor: "#000a12",
  highlight: "#E0B1CB",
  dark: "#00072D",
  secondaryText: "#655E7A",
  imageHighlight: "#BE95C4",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#5E548E",
  headerColor: "#BE95C477",
  splashBg: "#231942",
};

export const greenTheme = {
  body: "#FFFFEB",
  text: "#003F2F",
  expTxtColor: "#000a12",
  highlight: "#dddf00",
  dark: "#00072D",
  secondaryText: "#4CA58F",
  imageHighlight: "#55a630",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#007f5f",
  headerColor: "#55a63077",
  splashBg: "#003F2F",
};

export const redTheme = {
  body: "#FFF8E6",
  text: "#6a040f",
  expTxtColor: "#000a12",
  highlight: "#ffba08",
  dark: "#03071e",
  secondaryText: "#964F56",
  imageHighlight: "#dc2f02",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#9d0208",
  headerColor: "#dc2f0277",
  splashBg: "#6a040f",
};

export const blackTheme = {
  body: "#E5E5E5",
  text: "#14213d",
  expTxtColor: "#000a12",
  highlight: "#ffffff",
  dark: "#000000",
  secondaryText: "#5A6377",
  imageHighlight: "#fca311",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#8d99ae",
  headerColor: "#fca31177",
  splashBg: "#14213d",
};

export const pinkTheme = {
  body: "#FEE9F2",
  text: "#620E34",
  expTxtColor: "#000a12",
  highlight: "#FBA7CD",
  dark: "#31071A",
  secondaryText: "#ef476f",
  imageHighlight: "#ef476f",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#8d99ae",
  headerColor: "#ef476f77",
  splashBg: "#620E34",
};

export const violetTheme = {
  body: "#F4EEFC",
  text: "#430A58",
  expTxtColor: "#000a12",
  highlight: "#D6BEF4",
  dark: "#21052C",
  secondaryText: "#875599",
  imageHighlight: "#9b5de5",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#763D8B",
  headerColor: "#9b5de577",
  splashBg: "#430A58",
};

export const tealTheme = {
  body: "#E6FAF5",
  text: "#084c61",
  expTxtColor: "#000a12",
  highlight: "#9BEED8",
  dark: "#031E26",
  secondaryText: "#528190",
  imageHighlight: "#07beb8",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#56a3a6",
  headerColor: "#07beb877",
  splashBg: "#084c61",
};

export const orangeTheme = {
  body: "#FFF0EA",
  text: "#99401F",
  expTxtColor: "#000a12",
  highlight: "#FFB59A",
  dark: "#33150A",
  secondaryText: "#CC552A",
  imageHighlight: "#FF6B35",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#d7263d",
  headerColor: "#FF6B3577",
  splashBg: "#99401F",
};

export const yellowTheme = {
  body: "#FFD95D",
  text: "#5f4339",
  expTxtColor: "#000a12",
  highlight: "#ffff73",
  dark: "#8d6e63",
  secondaryText: "#5f4339",
  imageHighlight: "#ffea3d",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#4e342e",
  headerColor: "#FFEA3D",
  splashBg: "#5f4339",
};

export const materialDarkTheme = {
  body: "#263238",
  text: "#aeaeae",
  expTxtColor: "#000a12",
  highlight: "#4f5b62",
  dark: "#000a12",
  secondaryText: "#aeaeae",
  imageHighlight: "#607d8b",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#8eacbb",
  headerColor: "#34515e",
  splashBg: "#4f5b62",
};

export const materialLightTheme = {
  body: "#ffffff",
  text: "#4c2b91",
  expTxtColor: "#000000",
  highlight: "#E9E3F5",
  dark: "#1d0c41",
  secondaryText: "#7d56c2",
  imageHighlight: "#2b1958",
  compImgHighlight: "#E1E2E1",
  jacketColor: "#E1E2E1",
  headerColor: "#E1E2E1",
  splashBg: "#7d56c2",
};

export const materialTealTheme = {
  body: "#ffffff",
  text: "#05505E",
  expTxtColor: "#000000",
  highlight: "#a1dded",
  dark: "#07292c",
  secondaryText: "#05505E",
  imageHighlight: "#0a343c",
  compImgHighlight: "#E1E2E1",
  jacketColor: "#E1E2E1",
  headerColor: "#E1E2E1",
  splashBg: "#05505E",
};

export const myCustomTheme = {
  // primaryBackgroundColor: "#5BA199",
  // primaryBackgroundColor: "#22333b",
  primaryBackgroundColor: "#FFF",
  primaryTextColor: "#000000",
  primaryFont: "Google Sans Regular", 
  secondaryFont: "Google Sans Regular", 
  primaryButtonColor: "#00457E",
  primaryButtonTextColor: "#FFF",
  secondaryButtonColor: "#2F70AF",
  // font: "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;",
  // font: "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;",

  secondaryBackgroundColor: "#45434C",
  secondaryTextColor: "#fff",
  descriptionTextColor: "gray",
  titleTextColor: "#16375C",

  thirdBackgroundColor: "#35454c",
  thirdTextColor: "#00457E",

  footerBackgroundColor: "#232323",

  headerBackgroundColor: "#00457E",
  buttonHoverColor: "#19578a",

  logo: "#40916c",
  text: "#000000",
  // titleFontSize: "8rem",
  // subTitleFontSize: "3.7rem",
  descriptionTitleFontSize: "2rem",

  headerTextColor: "#FFFFFF",

  greetingTextColor: "#000000",
  greetingBackgroundColor: "#5BA199",

  descriptionFontSize: "1.65rem",
  expTxtColor: "#000000",
  highlight: "#a1dded",
  dark: "#07292c",
  secondaryText: "#ffffff",
  imageHighlight: "#0a343c",
  compImgHighlight: "#E1E2E1",
  jacketColor: "#E1E2E1",
  splashBg: "#05505E",
};

export const myCustomWhiteTheme = {
  // primaryBackgroundColor: "#5BA199",
  primaryBackgroundColor: "#FFF",
  primaryTextColor: "#000000",
  primaryFont: "Google Sans Bold", 
  secondaryFont: "Google Sans Regular", 
  primaryButtonColor: "#00457E",
  secondaryButtonColor: "#2F70AF",
  // font: "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;",
  // font: "BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;",

  secondaryBackgroundColor: "#45434C",
  secondaryTextColor: "#fff",

  thirdBackgroundColor: "white",
  thirdTextColor: "#81BECE",

  // Branched off from materialTealTheme
  logo: "#40916c",
  text: "#000000",
  // titleFontSize: "8rem",
  // subTitleFontSize: "3.7rem",
  descriptionTitleFontSize: "2rem",

  headerTextColor: "#FFFFFF",

  greetingTextColor: "#000000",
  greetingBackgroundColor: "#5BA199",

  descriptionFontSize: "1.65rem",
  expTxtColor: "#000000",
  highlight: "#a1dded",
  dark: "#07292c",
  secondaryText: "#ffffff",
  imageHighlight: "#0a343c",
  compImgHighlight: "#E1E2E1",
  jacketColor: "#E1E2E1",
  splashBg: "#05505E",
};

export const chosenTheme = myCustomTheme;
