import React, { useState } from 'react';
import './ContactUsButtonMobile.css';
import { KONTAKT_PATH } from '../../../utils/constants';

function ContactUsButtonMobile({ font, backgroundColor, buttonHoverColor, textColor }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="contact-us-container-mobile">
      <a
        href={KONTAKT_PATH}
        className="contact-us-button-mobile"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          fontFamily: font,
          backgroundColor: isHovered ? buttonHoverColor : backgroundColor,
          color: textColor,
        }}
      >
        Kontakta oss
      </a>
    </div>
  );
}

export default ContactUsButtonMobile;