import React from "react";
import PrivateSewageSystemBackgroundImage from "../../components/backgroundImage/PrivateSewageSystemBackgroundImage";
import PrivateSewageSystemItem from "./PrivateSewageSystemItem";
import { privateSewageSystem } from "../../portfolio";

function PrivateSewageSystemInformation(props) {
  const theme = props.theme
  return (
    <div className="heatpumps-information-container">
      <div className="heatpumps-information-section-container" style={{ color: props.theme.textColor, fontFamily: props.theme.primaryFont }}>
        
        <PrivateSewageSystemBackgroundImage
          theme={props.theme}
          font={props.theme.primaryFont}
        />
        {privateSewageSystem.data.map((project) => {
          return (
            <>
              {project.imageAlign === "left" && (
                <PrivateSewageSystemItem theme={theme} titleTextColor={props.titleTextColor} textColor={props.textColor} key={project.id} project={project} />
              )}
              {project.imageAlign === "right" && (
                <PrivateSewageSystemItem theme={theme} titleTextColor={props.titleTextColor} textColor={props.textColor} key={project.id} project={project} />
              )}
            </>
          );
        })}
      </div>
        
    </div>
  );
}

export default PrivateSewageSystemInformation;