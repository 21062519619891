import React, { Component } from "react";
import './Image.css'

export default class Image extends Component {
  render() {
    const imageContext = require.context('../../assets/images', false, /\.(webp|png|jpe?g)$/);
    const style = this.props.style ? this.props.style : {}
    const imageUrl = imageContext(this.props.src);
    const alt = this.props.alt ? this.props.alt : ""
    return <img className="image" style={{ ...style }} src={imageUrl} alt={alt} />;
  }
}
