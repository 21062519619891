// ContactForm.js
import React, { useState } from "react";
import "./ContactForm.css";

function ContactForm(props) {
  const onMouseEnter = (event, color) => {
    const el = event.target;
    el.style.backgroundColor = color;
  };
  
  const onMouseOut = (event) => {
    const el = event.target;
    el.style.backgroundColor = props.buttonColor;
  };

  
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phoneNumber: "",
    address: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    // Here you can handle the form submission, e.g., send it to an API
  };

  return (
    <div className="contact-form-container">
      <span className="contact-form-heading" style={{ color: props.theme.thirdTextColor }}>Kontakta oss</span>
      <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            className="contact-form-name-input"
            placeholder="Namn"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type="email"
            name="email"
            className="contact-form-email-input"
            placeholder="Epost"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="phone"
            name="Telefonnummer"
            className="contact-form-phone-number-input"
            placeholder="Telefonnummer"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          <input
            type="text"
            name="Address"
            className="contact-form-address-input"
            placeholder="Address"
            value={formData.address}
            onChange={handleChange}
          />
          <textarea
            name="message"
            className="contact-form-message-input"
            placeholder="Meddelande"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          <button 
            className="contact-form-submit" 
            type="submit"
            style={{
               backgroundColor: props.buttonColor ,
               color: props.buttonTextColor
            }}
            onMouseEnter={(event) => onMouseEnter(event, props.theme.buttonHoverColor)}
            onMouseOut={(event) => onMouseOut(event)}
          >
            Skicka meddelande
          </button>
      </form>
    </div>
  );
}

export default ContactForm;