import React from "react";
import "./Bathroom.css";
import BathroomItem from "./BathroomItem";
import { bathroom } from "../../portfolio";

function Bathroom(props) {
  const theme = props.theme
  return (
    <div className="bathrooms-container">
      <div className="bathrooms-section-container" style={{ fontFamily: props.font}}>
        {bathroom.data.map((project) => {
          return (
            <>
              {project.imageAlign === "left" && (
                <BathroomItem theme={theme} titleTextColor={props.titleTextColor} textColor={props.textColor} key={project.id} project={project} />
              )}
              {project.imageAlign === "right" && (
                <BathroomItem theme={theme} titleTextColor={props.titleTextColor} textColor={props.textColor} key={project.id} project={project} />
              )}
            </>
          );
        })}
      </div>  
    </div>
  );
}

export default Bathroom;