import React, { Component } from "react";
import "./Header.css";
import { NavLink, Link } from "react-router-dom";
import { settings } from "../../portfolio.js";
import Logo from "../../components/logo/Logo"
import SeoHeader from "../seoHeader/SeoHeader";
import { BADRUM_PATH, ENSKILT_AVLOPP_PATH, HOME_PATH, KONTAKT_PATH, VARMEPUMPAR_PATH } from "../../utils/constants"

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.backgroundColor = "transparent";
};

class Header extends Component {
  render() {
    const theme = this.props.theme;
    const link = settings.isSplash ? "/splash" : "/";
    const seoTitle = this.props.seoTitle;
    const seoDescription = this.props.seoDescription;
    const includeLogo = this.props.includeLogo !== undefined ? this.props.includeLogo : true;
    const style = this.props.style ? this.props.style : {}

    const headerFontSize = '1.5rem'
    return (
      <div>
        <SeoHeader seoTitle={seoTitle} seoDescription={seoDescription} />
        <div className="header-container" style={{ ...style, backgroundColor: this.props.backgroundColor, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', fontFamily: this.props.font }}>
          <header className="header" style={{ color: theme.primaryBackgroundColor}}>
            <NavLink to={link} tag={Link} className="logo">
              <Logo includeLogo={includeLogo}/>
            </NavLink>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn">
              <span className="navicon"></span>
            </label>
            <ul className="menu" style={{ backgroundColor: theme.backgroundColor }}>
            <li>
                <NavLink
                    to={HOME_PATH}
                    tag={Link}
                    className={"hide-on-desktop"}
                    activeStyle={{ fontWeight: "bold" }}
                    style={{ color: this.props.textColor, fontSize: headerFontSize, fontWeight: 100 }}
                    onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                    onMouseOut={(event) => onMouseOut(event)}
                  >
                    Hem
                </NavLink>
              </li>
              <li>
                <NavLink
                    to={VARMEPUMPAR_PATH}
                    tag={Link}
                    activeStyle={{ fontWeight: "bold" }}
                    style={{ color: this.props.textColor, fontSize: headerFontSize, fontWeight: 100 }}
                    onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                    onMouseOut={(event) => onMouseOut(event)}
                  >
                    Värmepumpar
                </NavLink>
              </li>

              <li>
                <NavLink
                    to={BADRUM_PATH}
                    tag={Link}
                    activeStyle={{ fontWeight: "bold" }}
                    style={{ color: this.props.textColor, fontSize: headerFontSize, fontWeight: 100 }}
                    onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                    onMouseOut={(event) => onMouseOut(event)}
                  >
                    Badrum
                </NavLink>
              </li>
              <li>
                <NavLink
                    to={ENSKILT_AVLOPP_PATH}
                    tag={Link}
                    activeStyle={{ fontWeight: "bold" }}
                    style={{ color: this.props.textColor, fontSize: headerFontSize, fontWeight: 100 }}
                    onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                    onMouseOut={(event) => onMouseOut(event)}
                  >
                    Enskilt Avlopp
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={KONTAKT_PATH}
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: this.props.textColor, fontSize: headerFontSize, fontWeight: 100 }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  Kontakta oss
                </NavLink>
              </li>
            </ul>
          </header>
        </div>
      </div>
    );
  }
}
export default Header;
