import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  const backgroundColor = props.backgroundColor;
  const textColor = props.textColor;
  return (
    <Fade>
      <div className="footer-container" style={{ backgroundColor: backgroundColor, color: textColor }}>
        <div className="footer-top-container">
          <div className="footer-links-container">
            <div className="footer-links-name-container">
              <span className="footer-links-header">Namn</span>
              <span className="footer-links-text">Jonas Lundholm</span>
            </div>
            <div className="footer-links-name-container">
              <span className="footer-links-header">Email</span>
              <span className="footer-links-text">jonas@jlundholm.com</span>
            </div>
          </div>
          
          <div className="footer-links-container">
            <span className="footer-links-header">Address</span>
            <span className="footer-links-text">Testgatan 3</span>
            <span className="footer-links-text">112 29</span>
            <span className="footer-links-text">Gävle</span>
          </div>
        </div>
        <div className="footer-bottom-container">
          <span className="footer-bottom-info">Det här är en handbyggd demosida för ett påhittat VVS-företag i Gästriklandsområdet.</span>
          <span className="footer-bottom-info">Vill du ha en egen hemsida för ditt företag eller event? Skicka ett mail till jonas@jlundholm.com</span>
        </div>
      </div>
    </Fade>
  );
}
