import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./HeatPumpProduct.css";
import { Fade } from "react-reveal";
import Image from "../../components/image/Image.js";

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event, backgroundColor) => {
  const el = event.target;
  el.style.backgroundColor = backgroundColor;
};

class HeatPumpProduct extends Component {
  render() {
    const project = this.props.project;

    const workTextClassName =
      project.imageAlign === "left"
        ? "heatpump-text-div-left"
        : "heatpump-text-div-right";

    return (
      <div className="heatpump-main-div">
        {project.imageAlign === "left" && (
          <div className="heatpump-image-div" >
            <span className="heatpump-heading-left-mobile" style={{ color: this.props.titleColor }}>
              {/* For mobile we want the project heading to show up before the image*/}
              {project.title}
            </span>
            <Image src={project.imageLink}/>
          </div>
        )}
        
        {project.imageAlign === "left" && (
          <div className={workTextClassName} >
          <span className="heatpump-heading-desktop" style={{ color: this.props.titleColor }}>
            {/* For desktop we want the project heading to show up after the image*/}
            {project.title}
          </span>
          <Fade>
          <div
              className="heatpump-description-mobile"
              style={{ color: this.props.textColor }}
              dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
            ></div>
            <div
              className="heatpump-description-desktop"
              style={{ color: this.props.textColor }}
              dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
            ></div>
            <div className="heatpump-contact-us-button-container">
              <NavLink
                  to={project.buttonLink}
                  tag={project.buttonLink}
                  className="heatpump-contact-us-button-desktop" 
                  style={{ backgroundColor: project.buttonColor, color: project.buttonTextColor }}
                  onMouseEnter={(event) => onMouseEnter(event, project.buttonHoverColor)}
                  onMouseOut={(event) => onMouseOut(event, project.buttonColor)}
                  >
                  {project.buttonText}
              </NavLink>
            </div>
            <div className="heatpump-contact-us-button-container">
              <NavLink
                    to={project.buttonLink}
                    tag={project.buttonLink}
                    className="heatpump-contact-us-button-mobile" 
                    style={{ backgroundColor: project.buttonColor, color: project.buttonTextColor }}
                    onMouseEnter={(event) => onMouseEnter(event, project.buttonHoverColor)}
                    onMouseOut={(event) => onMouseOut(event, project.buttonColor)}
                    >
                    {project.buttonText}
                </NavLink>
            </div>
          </Fade>
        </div>
        )}
 
        {project.imageAlign === "right" && (
          <div className={workTextClassName}>
            <span className="heatpump-heading-desktop" style={{ color: this.props.titleColor }}>
              {/* For desktop we want the project heading to show up after the image*/}
              {project.title}
            </span>
            <Fade>
              <div
                className="heatpump-description-desktop"
                style={{ color: this.props.textColor }}
                dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
              ></div>
              <div className="heatpump-contact-us-button-container">
                <NavLink
                  to={project.buttonLink}
                  tag={project.buttonLink}
                  className="heatpump-contact-us-button-desktop" 
                  style={{ backgroundColor: project.buttonColor, color: project.buttonTextColor }}
                  onMouseEnter={(event) => onMouseEnter(event, project.buttonHoverColor)}
                  onMouseOut={(event) => onMouseOut(event, project.buttonColor)}
                  >
                  {project.buttonText}
                </NavLink>
              </div>
            </Fade>
          </div>
        )}
        
        {project.imageAlign === "right" && (
          <div className="heatpump-image-div-right-mobile">
            <span className="heatpump-heading-right-mobile" style={{ color: this.props.titleColor }}>
            {/* For desktop we want the project heading to show up after the image*/}
            {project.title}
          </span>
            <Image src={project.imageLink}/>
            <Fade>
            <div
                className="heatpump-description-mobile"
                style={{ color: this.props.textColor }}
                dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
              ></div>
            <div className="heatpump-contact-us-button-container">
              <NavLink
                to={project.buttonLink}
                tag={project.buttonLink}
                className="heatpump-contact-us-button-mobile" 
                style={{ backgroundColor: project.buttonColor, color: project.buttonTextColor }}
                onMouseEnter={(event) => onMouseEnter(event, project.buttonHoverColor)}
                onMouseOut={(event) => onMouseOut(event, project.buttonColor)}
                >
                {project.buttonText}
              </NavLink>
            </div>
            </Fade>

          </div>
        )}
        
        
      </div>
    );
  }
}

export default HeatPumpProduct;
