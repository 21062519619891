import React, { Component } from "react";
import "./BathroomItem.css";
import { Fade } from "react-reveal";
import Image from "../../components/image/Image.js";
import ContactUsButtonDesktop from "../../components/contactUs/contactUsButtonDesktop/ContactUsButtonDesktop";
import ContactUsButtonMobile from "../../components/contactUs/contactUsButtonMobile/ContactUsButtonMobile";

class BathroomItem extends Component {
  render() {
    const project = this.props.project;
    const theme = this.props.theme;

    const workTextClassName =
      project.imageAlign === "left"
        ? "bathroom-text-div-left"
        : "bathroom-text-div-right";
    
    return (
      <div className="bathroom-main-div">
        {project.imageAlign === "left" && (
          <div className="bathroom-image-div">
            <h1 className="bathroom-heading-left-mobile">
              {/* For mobile we want the project heading to show up before the image*/}
              {project.title}
            </h1>
            <Image src={project.imageLink}/>
          </div>
        )}
        
        {project.imageAlign === "left" && (
          <div className={workTextClassName} style={{ color: this.props.titleTextColor}}>
            <span className="bathroom-heading-desktop">
              {/* For desktop we want the project heading to show up after the image*/}
              {project.title}
            </span>
            <Fade>
            <div
                className="bathroom-description-mobile"
                style={{ color: this.props.textColor }}
                dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
              ></div>
              <div
                className="bathroom-description-desktop"
                style={{ color: this.props.textColor }}
                dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
              ></div>
            </Fade>
        </div>
        )}
 
        {project.imageAlign === "right" && (
          <div className={workTextClassName}>
          <span className="bathroom-heading-desktop"  style={{ color: this.props.titleTextColor}}>
            {/* For desktop we want the project heading to show up after the image*/}
            {project.title}
          </span>
          <Fade>
            <div
              className="bathroom-description-desktop"
              style={{ color: this.props.textColor }}
              dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
            ></div>
            <div className="bathroom-item-contact-us-desktop-button-container">
              <ContactUsButtonDesktop font={theme.primaryFont} backgroundColor={theme.primaryButtonColor} buttonHoverColor={theme.buttonHoverColor} textColor={theme.secondaryTextColor}/> 
            </div>

          </Fade>
        </div>
        )}
        
        {project.imageAlign === "right" && (
          <div className="bathroom-image-div-right-mobile">
          <span className="bathroom-heading-right-mobile"  style={{ color: this.props.titleTextColor}}>
            {/* For desktop we want the project heading to show up after the image*/}
            {project.title}
          </span>
            <Image src={project.imageLink}/>
            <Fade>
            <div
                className="bathroom-description-mobile"
                style={{ color: this.props.textColor }}
                dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
              ></div>
              <div className="bathroom-item-contact-us-mobile-button">
                <ContactUsButtonMobile font={theme.primaryFont} backgroundColor={theme.primaryButtonColor} textColor={theme.secondaryTextColor}/> 
              </div>
            </Fade>
          </div>
        )}
        
      </div>
    );
  }
}

export default BathroomItem;
