import React, { Component } from "react";
import "./HomeBackgroundImage.css";
import ContactUsButtonDesktop from "../contactUs/contactUsButtonDesktop/ContactUsButtonDesktop.js"
import ContactUsButtonMobile from "../contactUs/contactUsButtonMobile/ContactUsButtonMobile.js"
import { Fade } from "react-reveal";
import Header from "../header/Header.js";

class HomeBackgroundImage extends Component {
  render() {
    const theme = this.props.theme
    return (
      <Fade>
        <div style={{ position: "relative", fontFamily: this.props.font}}>
          <div className="bg-image">
              <Header 
                theme={theme}
                seoTitle={"Home | Jonas Lundholm - Freelance Software Developer"}
                seoDescription={
                  "Jonas Lundholm is an experienced Freelance Software Developer focusing on building websites using modern technologies and great web design. With over 5 years in the industry I've worked with multiple successful startups"
                }
                textColor={'white'}
                includeLogo={false}
              />
            <div className="bg-image-text-container">
              <div className="bg-image-form-container">
                <div className="bg-image-text-title-container">
                  <span className="bg-image-text-title">
                    Nordiska Rör & VVS
                  </span>
                </div>
                <div className="bg-image-text-subtitle-container">
                  <span className="bg-image-text-subtitle">
                    Vi renoverar badrum, installerar värmepumpar och gräver enskilda avlopp i hela Gävle-Sandviken-Borlänge!
                  </span>
                </div>
                <div className="bg-image-contact-us-container-desktop">
                  <ContactUsButtonDesktop font={theme.primaryFont} backgroundColor={theme.primaryButtonColor} buttonHoverColor={theme.buttonHoverColor} textColor={theme.secondaryTextColor}/> 
                  <ContactUsButtonMobile font={theme.primaryFont} backgroundColor={theme.primaryButtonColor} buttonHoverColor={theme.buttonHoverColor} textColor={theme.secondaryTextColor}/> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default HomeBackgroundImage;
