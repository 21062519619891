import React from "react";
import "./Hello.css";
import HelloSection from "./HelloSection.js";

function Hello(props) {

  return (
    <div className="hello-container" style={{ backgroundColor: props.backgroundColor, color: props.textColor, fontFamily: props.font}}>
      <HelloSection
        theme={props.theme}
        titleTextColor={props.titleTextColor}
      />
        
    </div>
  );
}

export default Hello;