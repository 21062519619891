import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Contact from "../pages/contact/ContactComponent";
import HeatPumps from "../pages/heatpumps/HeatPumpsComponent";
import Bathroom from "../pages/bathroom/BathroomComponent";
import PrivateSewageSystem from "../pages/privateSewageSystem/PrivateSewageSystemComponent"
import { VARMEPUMPAR_PATH, BADRUM_PATH, HOME_PATH, ENSKILT_AVLOPP_PATH, KONTAKT_PATH } from "../utils/constants";
import ScrollToTop from "../ScrollToTop";

export default class Main extends Component {
  render() {
    return (
      <>
        <BrowserRouter basename="/">

          <ScrollToTop />
          <Switch>
            <Route
              path={HOME_PATH}
              exact
              render={(props) => <Home {...props} theme={this.props.theme} />}
            />
            <Route
              path={KONTAKT_PATH}
              render={(props) => <Contact {...props} theme={this.props.theme} />}
            />
            <Route
              path={VARMEPUMPAR_PATH}
              render={(props) => <HeatPumps {...props} theme={this.props.theme} />}
            />
            <Route
              path={BADRUM_PATH}
              render={(props) => <Bathroom {...props} theme={this.props.theme} />}
            />
            <Route
              path={ENSKILT_AVLOPP_PATH}
              render={(props) => <PrivateSewageSystem {...props} theme={this.props.theme} />}
            />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}
