import React from "react";
import Header from "../../components/header/Header.js";
import ContactForm from "../../containers/contactForm/ContactForm.js";
import Footer from "../../components/footer/Footer.js";

const Contact = (props) => {
  return (
    <div>
      {/* {showModal && (
        <Modal showModal={showModal} onModalDismiss={onModalDismiss} />
      )} */}
        <Header
          theme={props.theme}
          seoTitle={"Home | Jonas Lundholm - Freelance Software Developer"}
          seoDescription={
            "Jonas Lundholm is an experienced Freelance Software Developer focusing on building websites using modern technologies and great web design. With over 5 years in the industry I've worked with multiple successful startups"
          }
          backgroundColor={props.theme.headerBackgroundColor}
          textColor={props.theme.secondaryTextColor}
        />
        <ContactForm
          theme={props.theme}
          font={props.theme.primaryFont}
          textColor={props.theme.secondaryTextColor}
          backgroundColor={props.theme.primaryBackgroundColor}
          buttonTextColor={props.theme.secondaryTextColor}
          buttonColor={props.theme.primaryButtonColor}
          buttonHoverColor={props.theme.buttonHoverColor}
        />
        <Footer 
          backgroundColor={props.theme.footerBackgroundColor}
          textColor={props.theme.secondaryTextColor}
        />
    </div>
  );
};

export default Contact;
