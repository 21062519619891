import React from "react";
import HomeBackgroundImage from "../../components/backgroundImage/HomeBackgroundImage";
import Offerings from "../../containers/offering/Offering.js";
import Hello from "../../containers/hello/Hello";
import Footer from "../../components/footer/Footer.js";

const IMAGE_FILE_LOCATION = "home/sandviken_fran_arsunda.webp"
const Home = (props) => {
  const backgroundImage = require(`../../assets/images/${IMAGE_FILE_LOCATION}`);

  return (
    <div>
      {/* {showModal && (
        <Modal showModal={showModal} onModalDismiss={onModalDismiss} />
      )} */}
        <HomeBackgroundImage
          theme={props.theme}
          font={props.theme.primaryFont}
          src={backgroundImage}
        />
        <Hello
          theme={props.theme}
          font={props.theme.primaryFont}
          titleTextColor={props.theme.titleTextColor}
          textColor={'gray'}
          backgroundColor={props.theme.primaryBackgroundColor}
        />
        {/* <Greeting
          theme={props.theme}
          font={props.theme.primaryFont}
          textColor={props.theme.secondaryTextColor}
          backgroundColor={props.theme.secondaryBackgroundColor}
        /> */}
        <Offerings
          theme={props.theme}
          font={props.theme.primaryFont}
          backgroundColor={'#16375C'}
          serviceBackgroundColor={props.theme.primaryBackgroundColor}
          buttonTextColor={props.theme.secondaryTextColor}
          buttonColor={props.theme.primaryButtonColor}
          buttonHoverColor={props.theme.buttonHoverColor}
          iconColor={props.theme.thirdTextColor}
          titleColor={'#16375C'}
          textColor={'gray'}
        />
        <Footer 
          backgroundColor={props.theme.footerBackgroundColor}
          textColor={props.theme.secondaryTextColor}
        />
    </div>
  );
};

export default Home;
