import React, { Component } from "react";
import "./PrivateSewageSystemItem.css"
import { Fade } from "react-reveal";
import Image from "../../components/image/Image.js";
import ContactUsButtonDesktop from "../../components/contactUs/contactUsButtonDesktop/ContactUsButtonDesktop";
import ContactUsButtonMobile from "../../components/contactUs/contactUsButtonMobile/ContactUsButtonMobile";

class PrivateSewageSystemItem extends Component {
  render() {
    const project = this.props.project;
    const theme = this.props.theme;

    const workTextClassName =
      project.imageAlign === "left"
        ? "private-sewage-system-text-div-left"
        : "private-sewage-system-text-div-right";
    
    return (
      <div className="private-sewage-system-main-div">
        {project.imageAlign === "left" && (
          <div className="private-sewage-system-image-div">
            <h1 className="private-sewage-system-heading-left-mobile">
              {/* For mobile we want the project heading to show up before the image*/}
              {project.title}
            </h1>
            <Image src={project.imageLink}/>
          </div>
        )}
        
        {project.imageAlign === "left" && (
          <div className={workTextClassName} style={{ color: this.props.titleTextColor}}>
            <span className="private-sewage-system-heading-desktop">
              {/* For desktop we want the project heading to show up after the image*/}
              {project.title}
            </span>
            <Fade>
              <div
                  className="private-sewage-system-description-mobile"
                  style={{ color: this.props.textColor }}
                  dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}/>
                <div
                  className="private-sewage-system-description-desktop"
                  style={{ color: this.props.textColor }}
                  dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}/>
            </Fade>
            <div className="private-sewage-system-item-contact-us-desktop-button-container">
                <ContactUsButtonDesktop font={theme.primaryFont} backgroundColor={theme.primaryButtonColor} buttonHoverColor={theme.buttonHoverColor} textColor={theme.secondaryTextColor}/> 
            </div>
        </div>
        )}
 
        {project.imageAlign === "right" && (
          <div className={workTextClassName}>
          <span className="private-sewage-system-heading-desktop"  style={{ color: this.props.titleTextColor}}>
            {/* For desktop we want the project heading to show up after the image*/}
            {project.title}
          </span>
          <Fade>
            <div
              className="private-sewage-system-description-desktop"
              style={{ color: this.props.textColor }}
              dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
            ></div>
            <div className="private-sewage-system-item-contact-us-desktop-button-container">
              <ContactUsButtonDesktop font={theme.primaryFont} backgroundColor={theme.primaryButtonColor} buttonHoverColor={theme.buttonHoverColor} textColor={theme.secondaryTextColor}/> 
            </div>

          </Fade>
        </div>
        )}
        
        {project.imageAlign === "right" && (
          <div className="private-sewage-system-image-div-right-mobile">
          <span className="private-sewage-system-heading-right-mobile"  style={{ color: this.props.titleTextColor}}>
            {/* For desktop we want the project heading to show up after the image*/}
            {project.title}
          </span>
            <Image src={project.imageLink}/>
            <Fade>
            <div
                className="private-sewage-system-description-mobile"
                style={{ color: this.props.textColor }}
                dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
              ></div>
              <div className="private-sewage-system-item-contact-us-mobile-button">
                <ContactUsButtonMobile font={theme.primaryFont} backgroundColor={theme.primaryButtonColor} textColor={theme.secondaryTextColor}/> 
              </div>
            </Fade>
          </div>
        )}
        
      </div>
    );
  }
}

export default PrivateSewageSystemItem;
