import React, { Component } from "react";
import './ThumbnailImage.css'
const imageContext = require.context('../../assets/images/thumbnail', false, /\.(webp|png|jpe?g)$/);

export default class ThumbnailImage extends Component {
  render() {
    const style = this.props.style ? this.props.style : {}

    const imageUrl = imageContext(this.props.src);
    const alt = this.props.alt ? this.props.alt : ""
    return <img style={{...style }}className="thumbnail-image" src={imageUrl} alt={alt}/>;
  }
}
