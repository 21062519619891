import React, { Component } from "react";
import "./HelloSection.css";
import { aboutUs } from "../../portfolio";
import HelloItem from "./HelloItem"

class HelloSection extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="hello-section-container">
        {aboutUs.data.map((project) => {
          return (
            <>
              {project.imageAlign === "left" && (
                <HelloItem 
                  theme={theme} 
                  titleTextColor={this.props.titleTextColor}
                  key={project.id} 
                  project={project} 
                />
              )}
              {project.imageAlign === "right" && (
                <HelloItem 
                  theme={theme} 
                  titleTextColor={this.props.titleTextColor}
                  key={project.id} 
                  project={project} 
                />
              )}
            </>
          );
        })}
      </div>
    );
  }
}

export default HelloSection;
