import React from "react";
import { NavLink } from 'react-router-dom';
import "./OfferingCard.css";
import ThumbnailImage from "../../components/thumbnail/ThumbnailImage.js";

export default function OfferingCard({
  title,
  imageLink,
  description,
  buttonLink,
  buttonText,
  buttonColor,
  buttonHoverColor,
  buttonTextColor,
  textColor,
  titleColor,
  backgroundColor,
  font,
}) {
  const onMouseEnter = (event, color) => {
    const el = event.target;
    el.style.backgroundColor = color;
  };
  
  const onMouseOut = (event) => {
    const el = event.target;
    el.style.backgroundColor = buttonColor;
  };

  const imageStyle = { borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' }
  console.log('buttonHoverColor', buttonHoverColor)
  return (
    <NavLink
      to={buttonLink}
      tag={buttonLink}
      className="offering-card-container"
      style={{ backgroundColor: backgroundColor, color: textColor, fontFamily: font, ...imageStyle }}
    >
      <ThumbnailImage 
        src={imageLink}
        style={imageStyle} 
      />
      <div style={{color: titleColor}} className="offering-card-title">{title}</div>
      <div className="offering-card-description">{description}</div>
      <NavLink
          to={buttonLink}
          className={"offering-card-button"}
          style={{ backgroundColor: buttonColor, color: buttonTextColor }}
          onMouseEnter={(event) => onMouseEnter(event, buttonHoverColor)}
          onMouseOut={(event) => onMouseOut(event)}
        >
          {buttonText}
      </NavLink>
    </NavLink>
  );
}
