import React from 'react';
import { NavLink } from "react-router-dom";
import './ContactUsButtonDesktop.css';
import { KONTAKT_PATH } from '../../../utils/constants';

function ContactUsButtonDesktop({ font, backgroundColor, buttonHoverColor, textColor }) {

  const onMouseEnter = (event, color) => {
    const el = event.target;
    el.style.backgroundColor = color;
  };
  
  const onMouseOut = (event, backgroundColor) => {
    const el = event.target;
    el.style.backgroundColor = backgroundColor;
  };
  
  return (
    <NavLink
      to={KONTAKT_PATH}
      className="contact-us-button-desktop"
      style={{ fontFamily: font, backgroundColor: backgroundColor, color: textColor }}
      onMouseEnter={(event) => onMouseEnter(event, buttonHoverColor)}
      onMouseOut={(event) => onMouseOut(event, backgroundColor)}>
      Kontakta oss
    </NavLink>
  );
}

export default ContactUsButtonDesktop;