import React from "react";
import Header from "../../components/header/Header.js";
import HeatPumpProducts from "../../containers/heatpumps/HeatPumpProducts.js";
import HeatPumpsInformation from "../../containers/heatpumps/HeatPumpsInformation.js"
import Footer from "../../components/footer/Footer.js";

const HeatPumpsComponent = (props) => {
  return (
    <div>
      {/* {showModal && (
        <Modal showModal={showModal} onModalDismiss={onModalDismiss} />
      )} */}
        <Header
          theme={props.theme}
          seoTitle={"Home | Jonas Lundholm - Freelance Software Developer"}
          seoDescription={
            "Jonas Lundholm is an experienced Freelance Software Developer focusing on building websites using modern technologies and great web design. With over 5 years in the industry I've worked with multiple successful startups"
          }
          backgroundColor={props.theme.headerBackgroundColor}
          textColor={props.theme.secondaryTextColor}
        />
        <HeatPumpsInformation
          theme={props.theme}
          font={props.theme.primaryFont}
          textColor={props.theme.descriptionTextColor}
          titleTextColor={props.theme.titleTextColor}
          buttonHoverColor={props.theme.buttonHoverColor}
          backgroundColor={props.theme.primaryBackgroundColor}
        />
        <HeatPumpProducts
          theme={props.theme}
          font={props.theme.primaryFont}
          textColor={props.theme.descriptionTextColor}
          titleTextColor={props.theme.titleTextColor}
          buttonHoverColor={props.theme.buttonHoverColor}
          backgroundColor={props.theme.primaryBackgroundColor}
        />
        <Footer 
          backgroundColor={props.theme.footerBackgroundColor}
          textColor={props.theme.secondaryTextColor}
        />
    </div>
  );
};

export default HeatPumpsComponent;
