import React, { Component } from "react";
import "./HelloItem.css";
import { Fade } from "react-reveal";
import Image from "../../components/image/Image.js";

class HelloItem extends Component {
  render() {
    const project = this.props.project;

    const workTextClassName =
      project.imageAlign === "left"
        ? "hello-item-text-div-left"
        : "hello-item-text-div-right";

    const imageStyle = { borderRadius: '1.8rem' };

    return (
      <div className="hello-item-main-div">
        {project.imageAlign === "left" && (
          <div className="hello-item-image-div-left">
            <h1 className="hello-item-heading-left-mobile" style={{ color: this.props.titleTextColor }}>
              {/* For mobile we want the project heading to show up before the image*/}
              {project.title}
            </h1>
            <Image style={imageStyle} src={project.imageLink}/>
          </div>
        )}
        
        {project.imageAlign === "left" && (
          <div className={workTextClassName}>
          <span className="hello-item-heading-left-desktop" style={{ color: this.props.titleTextColor }}>
            {/* For desktop we want the project heading to show up after the image*/}
            {project.title}
          </span>
          <Fade>
          <div
              className="hello-item-description-mobile"
              dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
            ></div>
            <div
              className="hello-item-description-desktop"
              dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
            ></div>
          </Fade>
        </div>
        )}
 
        {project.imageAlign === "right" && (
          <div className={workTextClassName}>
          <span className="hello-item-heading-right-desktop" style={{ color: this.props.titleTextColor }}>
            {/* For desktop we want the project heading to show up after the image*/}
            {project.title}
          </span>
          <Fade>
            <div
              className="hello-item-description-desktop"
              dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
            ></div>
          </Fade>
        </div>
        )}
        
        {project.imageAlign === "right" && (
          <div className="hello-item-image-div-right-mobile">
            <span className="hello-item-heading-right-mobile" style={{ color: this.props.titleTextColor }}>
            {/* For desktop we want the project heading to show up after the image*/}
            {project.title}
          </span>
            <Image style={imageStyle} src={project.imageLink}/>
            <Fade>
            <div
                className="hello-item-description-mobile"
                dangerouslySetInnerHTML={{ __html: project.descriptionHtml }}
              ></div>
            </Fade>
          </div>
        )}
        
      </div>
    );
  }
}

export default HelloItem;
